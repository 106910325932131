import axios from "axios"
import {setFetchMessage} from "../notification";

export const ACTIONS = {
    GET_ALL_GAMPAIGNS: "GET_ALL_GAMPAIGNS",
    CANCEL_ORDER: "CANCEL_ORDER",
    SET_IS_FETCHING: "SET_IS_GAMPAIGN_FETCHING",
    SET_REVIEW_CAMPAIGN_DATA: "SET_REVIEW_CAMPAIGN_DATA",
    SET_REVIEW_CAMPAIGN_ERROR: "SET_REVIEW_CAMPAIGN_ERROR",
    START_REVIEW_CAMPAIGN_REQUEST: "START_REVIEW_CAMPAIGN_REQUEST",
    START_REVIEW_TOP8_CAMPAIGN_REQUEST: "START_REVIEW_TOP8_CAMPAIGN_REQUEST",
    GET_STRIPE_SESSION: "GET_STRIPE_SESSION",
    SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
    NO_DATA: "NO_GAMPAIGNS_DATA",
    NEW_CAMPAIGN: "NEW_CAMPAIGN",
    RESET_NEW_CAMPAIGN: "RESET_NEW_CAMPAIGN",
    CHANGED_STATUS: "CHANGED_STATUS_CAMPAIGN",
}

export const getAllCampaigns = (status = 'active', currentPage = 1, perPage = 5) => {
    return dispatch => {
        dispatch(setIsFetching(true))
        axios
            .get(
                `/api/product/list/${status}`,
                {params: {per_page: perPage, current_page: currentPage}}
            )
            .then(response => {
                dispatch(setCurrentPage(currentPage))
                dispatch({
                    type: ACTIONS.GET_ALL_GAMPAIGNS,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: ACTIONS.NO_DATA,
                });
                if (error.status != 400)
                    dispatch(setFetchMessage(error.response.data.message, 'error'));
            })


    }
};

export const setIsFetching = (bool) => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_IS_FETCHING,
            payload: bool,
        });
    }
};

export const setCurrentPage = (page) => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_CURRENT_PAGE,
            payload: page,
        });
    }
};

export const resetNewCampaign = () => {
    return dispatch => {
        dispatch({
            type: ACTIONS.RESET_NEW_CAMPAIGN,
        });
    }
};

export const newCampaign = (data, callback = () => {
}) => {
    return dispatch => {
        dispatch(setIsFetching(true))
        axios
            .post('/api/campaign', data)
            .then(response => {
                console.log(response)

                if (response) {
                    dispatch({
                        type: ACTIONS.NEW_CAMPAIGN,
                        payload: response.data.data
                    })
                    dispatch(setFetchMessage(response.data.message, 'success'))

                    callback(response.data?.code === 'campaign_launched');
                } else {
                    dispatch(setFetchMessage('Oops! Something went wrong!', 'error'))
                }
                dispatch(setIsFetching(false))

            })
            .catch(error => {
                console.log(error.response.data)
                if (error){
                        dispatch({
                            type: ACTIONS.SET_REVIEW_CAMPAIGN_ERROR,
                            payload: error.response.data
                        });
                    dispatch(setFetchMessage(error.response.data.message, 'error'))
                    dispatch(setIsFetching(false))
                }

            })
    }
}


export const changeCampaignStatus = (id, status, callback = () => {
}) => {

    return dispatch => {
        axios
            .post(`/api/product/${id}/${status}`)
            .then(response => {
                if (response.status == 200) {
                    dispatch({
                        type: ACTIONS.CHANGED_STATUS
                    })
                    dispatch(setFetchMessage(response.data.message, response.data.status))
                    callback()
                } else {
                    console.error(response);
                    dispatch(setFetchMessage('Oops! Something went wrong!', 'error'))
                }
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const resetReviewCampaignData = (data = "all") => {
    return dispatch => {
        if (data == 'all' || data == 'data') {
            dispatch({
                type: ACTIONS.SET_REVIEW_CAMPAIGN_DATA,
                payload: null
            });
        }
        if (data == 'all' || data == 'error') {
            dispatch({
                type: ACTIONS.SET_REVIEW_CAMPAIGN_ERROR,
                payload: null
            });
        }

    }
}


export const reviewCampaign = (data) => {

    data.product_id = data.product_id ?? 0;
    if(data.type == 'review-campaign'){
        data.reviews = data.reviews ?? 0;
        data.reviews_star = data.reviews_star ?? 0;
    }
    return dispatch => {
        dispatch({
            type: ACTIONS.START_REVIEW_CAMPAIGN_REQUEST,
        });
        axios
            .post('/api/campaign/reviewCampaign', data)
            .then(response => {
                dispatch({
                    type: ACTIONS.SET_REVIEW_CAMPAIGN_DATA,
                    payload: response.data
                });
            })
            .catch(error => {
                if (error.response?.data) {
                    dispatch({
                        type: ACTIONS.SET_REVIEW_CAMPAIGN_ERROR,
                        payload: error.response.data
                    });
                }
            })
    }
}


export const reviewTop8Campaign = (data) => {

    data.product_id = data.product_id ?? 0;
    data.type = 'cleaner-campaign';

    return dispatch => {
        dispatch({
            type: ACTIONS.START_REVIEW_TOP8_CAMPAIGN_REQUEST,
        });
        axios
            .post('/api/campaign/reviewCampaign', data)
            .then(response => {
                dispatch({
                    type: ACTIONS.SET_REVIEW_CAMPAIGN_DATA,
                    payload: response.data
                });
            })
            .catch(error => {
                if (error.response?.data) {
                    dispatch({
                        type: ACTIONS.SET_REVIEW_CAMPAIGN_ERROR,
                        payload: error.response.data
                    });
                }
            })
    }
}


export const cancelOrder = (order_id, callback = () => {
}) => {
    return dispatch => {
        axios
            .post(`/api/order/${order_id}/cancel`, {order_id: order_id})
            .then(response => {
                dispatch({
                    type: ACTIONS.CANCEL_ORDER,
                    payload: order_id
                });
                callback()
            })
            .catch(error => {
                console.log(['error newCampaign, check your code', error])
            })
    }
}