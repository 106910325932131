
import axios from "axios"
import {setFetchMessage} from "../notification";

export const ACTIONS = {
    GET_ALL_GAMPAIGNS: "GET_ALL_GAMPAIGNS_FINISHED",
    SET_IS_FETCHING: "SET_IS_GAMPAIGN_FETCHING_FINISHED",
    SET_CURRENT_PAGE: "SET_CURRENT_PAGE_FINISHED",
    NO_DATA: "NO_GAMPAIGNS_DATA_FINISHED",
    NEW_CAMPAIGN: "NEW_CAMPAIGN_FINISHED",
    RESET_NEW_CAMPAIGN: "RESET_NEW_CAMPAIGN_FINISHED",
}

export const getAllCampaigns = (currentPage= 1, perPage=5, asin="", market="de") => {
    return dispatch => {
        dispatch(setIsFetching(true))
        axios
            .get(
                `/api/product/list/finished`,
                { params: { per_page: perPage, current_page: currentPage, asin:asin, market:market } }
            )
            .then(response => {
                dispatch(setCurrentPage(currentPage))
                dispatch({
                    type: ACTIONS.GET_ALL_GAMPAIGNS,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: ACTIONS.NO_DATA,
                });
                if(error.response.status != 400)
                    dispatch(setFetchMessage(error.response.data.message, 'error'));
            })


    }
};

export const setIsFetching = (bool) => {
    return dispatch => {
                dispatch({
                    type: ACTIONS.SET_IS_FETCHING,
                    payload: bool,
                });
    }
};

export const setCurrentPage = (page) => {
    return dispatch => {
                dispatch({
                    type: ACTIONS.SET_CURRENT_PAGE,
                    payload: page,
                });
    }
};

export const resetNewCampaign = () => {
    return dispatch => {
                dispatch({
                    type: ACTIONS.RESET_NEW_CAMPAIGN,
                });
    }
};

export const newCampaign = (data) => {
    return dispatch => {
        axios
            .post('/api/campaign', data)
            .then(response => {
                dispatch({
                    type: ACTIONS.NEW_CAMPAIGN
                })
            })
    }
}
