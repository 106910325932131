import React, { PureComponent } from "react";
import classnames from "classnames";
import Navbars from "./components/navbar/Navbar";
import { connect } from "react-redux";
import StatusBar from "../components/status/StatusBar";
import SweetAlert from "react-bootstrap-sweetalert";
import {unSubscribe} from "../redux/actions/user";
import i18next from "i18next";

class VerticalLayout extends PureComponent {
  state = {
    width: window.innerWidth,
    currentLang: "en",
    appOverlay: false,
    customizer: false,
    currRoute: this.props.location.pathname,
    showConfirm: false
  };
  collapsedPaths = [];
  mounted = false;
  updateWidth = () => {
    if (this.mounted) {
      this.setState(prevState => ({
        width: window.innerWidth
      }));
    }
  };

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
    
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  handleCurrentLanguage = lang => {
    
  };

  handleAppOverlay = value => {
    if (value.length > 0) {
      this.setState({
        appOverlay: true
      });
    } else if (value.length < 0 || value === "") {
      this.setState({
        appOverlay: false
      });
    }
  };

  handleAppOverlayClick = () => {
    this.setState({
      appOverlay: false
    });
  };

  render() {

    return (
      <div
        className="wrapper vertical-layout dashboard-layout">
        <div
          className={classnames("app-content content", {
            "show-overlay": this.state.appOverlay === true
          })}
          onClick={this.handleAppOverlayClick}
        >
          <Navbars confirm={() => {
            this.setState({showConfirm: true});
          }}/>
          <div className="content-wrapper">
            {this.state.showConfirm && (<SweetAlert
                title={i18next.t("Are you sure? unsub")}
                cancelBtnText={i18next.t("Cancel")}
                showCancel
                cancelBtnCssClass="btn-danger unsubscribe-cancel-btn"
                confirmBtnCssClass={'btn-confirm'}
                confirmBtnText={i18next.t("Unsubscribe")}
                onConfirm={()=>{
                  this.props.unSubscribe()
                  this.setState({showConfirm: false});
                }}
                onCancel={()=>{this.setState({showConfirm: false});}}
            >
            </SweetAlert>)}
            {this.props.children}

          </div>
          <div id="modal-root" ></div>

        </div>

      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    app: state.customizer
  };
};
export default connect(mapStateToProps, {unSubscribe})(VerticalLayout);
