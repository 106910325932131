import {ACTIONS} from "../../actions/campaigns/finished";


const initialState = {
    campaigns: {
        data: []
    },
    isFetching: true,
    currentPage: 1,
    perPage: 5,
    filter: null,
    new_campaign: false
}

const active = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_ALL_GAMPAIGNS:
            return {
                ...state,
                campaigns: action.payload,
                isFetching: false,
            };
        case ACTIONS.SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.payload
            };
        case ACTIONS.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };
        case ACTIONS.NO_DATA:
            return {
                ...state,
                isFetching: false,
                campaigns:{ data: []}

            };
        case ACTIONS.NEW_CAMPAIGN:
            return {
                ...state,
                new_campaign: true
            };
        case ACTIONS.RESET_NEW_CAMPAIGN:
            return {
                ...state,
                new_campaign: false
            };
        default:
            return state
    }
};

export default active
