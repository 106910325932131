import axios from "axios"
import {setFetchMessage} from "../notification";
import notices from "../../../utils/notices";
export const ACTIONS = {
    GET_REFUNDS_LIST: "GET_REFUNDS_LIST",
    DOWNLOAD_REFUND_INVOICE: "DOWNLOAD_REFUND_INVOICE",
}


export const getRefundsPaginate = (params) => {

    return  dispatch => {
        axios
            .get(`/api/refunds/list`,{ params: params })
            .then(response => {
                dispatch({
                    type: ACTIONS.GET_REFUNDS_LIST,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: ACTIONS.GET_REFUNDS_LIST,
                    payload: {
                        data: [],
                        meta:{total:0}
                    }
                });
                if(error.response.status != 400)
                    dispatch(setFetchMessage(error.response.data.message, 'error'));
            })



    }
};

export const downloadRefundInvoice = (refund_id) => {
  return dispatch => {
    axios.get(`/api/refunds/${refund_id}/invoice`, {
        responseType: 'blob'
    }).then((response) => {
        console.log(response)
        dispatch({
            type: ACTIONS.DOWNLOAD_REFUND_INVOICE,
            data: response.data
        })
    }).catch(err => console.log(err))
  }
};