import {ACTIONS} from "../../actions/orders";

const initialState = {
    orders: [],
    orders_total:0
}

const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_ORDERS_LIST:
            return {
                ...state,
                orders: action.payload.data,
                orders_total: action.payload.meta.total
            };
        case ACTIONS.DOWNLOAD_ORDER_INVOICE:
            console.log(action.data)
            const url = window.URL.createObjectURL(new Blob([action.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `invoice.pdf`); 
            document.body.appendChild(link);
            link.click();
        default:
            return state
    }
};

export default productsReducer
