import {ACTIONS} from "../../actions/campaigns/index";


const initialState = {
    campaigns: {
        data: []
    },
    isFetching: true,
    top8IsFetching: false,
    reviewDataFetching:true,
    reviewDataError: null,
    reviewData: null,
    success_changes: false,
    currentPage: 1,
    perPage: 5,
    filter: null,
    new_campaign: false,
    new_campaign_data: {
        campaign_id:0,
        order_id:0
    }
}

const grouped = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_ALL_GAMPAIGNS:
            return {
                ...state,
                campaigns: action.payload,
                isFetching: false,
            };
        case ACTIONS.SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.payload
            };
        case ACTIONS.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };
        case ACTIONS.NO_DATA:
            return {
                ...state,
                isFetching: false
            };
        case ACTIONS.NEW_CAMPAIGN:
            return {
                ...state,
                new_campaign: true,
                new_campaign_data:action.payload
            };
        case ACTIONS.RESET_NEW_CAMPAIGN:
            return {
                ...state,
                new_campaign: false,
                new_campaign_data: {
                    campaign_id:0,
                    order_id:0
                }
            };
        case ACTIONS.CHANGED_STATUS:
            return {
                ...state,
            };
        case ACTIONS.START_REVIEW_CAMPAIGN_REQUEST:
            return {
                ...state,
                reviewDataFetching:true
            };
        case ACTIONS.START_REVIEW_TOP8_CAMPAIGN_REQUEST:
            return {
                ...state,
                reviewDataFetching:true
            };
        case ACTIONS.SET_REVIEW_CAMPAIGN_DATA:
            return {
                ...state,
                reviewDataFetching:false,
                top8IsFetching:false,
                reviewData:action.payload
            };
        case ACTIONS.SET_REVIEW_CAMPAIGN_ERROR:
            return {
                ...state,
                reviewDataFetching:false,
                top8IsFetching:false,
                reviewDataError: action.payload
            };
        default:
            return state
    }
};

export default grouped
