import React, {Suspense, lazy} from "react"
import ReactDOM from "react-dom"
import {Provider} from "react-redux"
import {Layout} from "@utils/context/Layout"
import * as serviceWorker from "./serviceWorker"
import {store} from "@store/storeConfig/store"
import Spinner from "@components/@vuexy/spinner/Loading-spinner"
import "./assets/scss/index.scss"
import {ToastContainer} from "react-toastify";
import {I18nextProvider} from "react-i18next";
import i18n from '@src/configs/i18n';

const LazyApp = lazy(() => import("./App"))
// configureDatabase()

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            <Suspense fallback={<Spinner/>} >
                <Layout>
                    <LazyApp/>
                    <ToastContainer/>
                </Layout>
            </Suspense>
        </Provider></I18nextProvider>,
    document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()