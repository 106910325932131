import {ACTIONS} from "../../actions/refunds";

const initialState = {
    refunds: [],
    refunds_total:0
}

const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_REFUNDS_LIST:
            return {
                ...state,
                refunds: action.payload.data,
                refunds_total: action.payload.meta.total
            };
        case ACTIONS.DOWNLOAD_REFUND_INVOICE:
            console.log(action.data)
            const url = window.URL.createObjectURL(new Blob([action.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `refund_invoice.pdf`); 
            document.body.appendChild(link);
            link.click();
        default:
            return state
    }
};

export default productsReducer
