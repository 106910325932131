import { combineReducers } from "redux"
import grouped from "./grouped";
import active from "./active";
import pending from "./pending";
import finished from "./finished";

const campaignsReducer = combineReducers({
    active,
    pending,
    finished,
    grouped
})

export default campaignsReducer
