import {ACTIONS} from "../../actions/jobs";


const initialState = {
    jobs: {},
    isFetching: false,
    fetching_product_id: 0
}

const jobsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_ALL_JOBS:
            state.jobs[action.product_id] = action.payload;
            return {
                ...state,
                jobs: state.jobs,
                isFetching: false,
                fetching_product_id:action.product_id
            };
        case ACTIONS.SET_IS_FETCHING:
            return {
                ...state,
                isFetching: true,
                fetching_product_id:action.payload
            };
        default:
            return state
    }
};

export default jobsReducer
