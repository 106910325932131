import {ACTIONS} from "../../actions/payment";
import Moment from 'moment';

const initialState = {
    stripe_session:null,
    wallet_paid: null,
    top8_paid: null,
    invoice_success: false,
    isFetching: false
}

const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_STRIPE_SESSION:
            return {
                ...state,
                stripe_session: action.payload
            };
        case ACTIONS.PAY_BY_WALLET:
            return {
                ...state,
                wallet_paid: action.payload,
                isFetching: false
            };
        case ACTIONS.PAY_BY_TOP8:
            return {
                ...state,
                top8_paid: action.payload,
                isFetching: false
            };
        case ACTIONS.SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.payload
            };
        case ACTIONS.DOWNLOAD_PAYMENT_INVOICE:
            console.log(action.data)
            const url = window.URL.createObjectURL(new Blob([action.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `invoice ${Moment().format('L')}.pdf`);
            document.body.appendChild(link);
            link.addEventListener('click', function(){
                setTimeout(() => {
                    link.remove();
                }, 200);
            })
            link.click();

            return {
                ...state,
                invoice_success: true
            }
        case ACTIONS.RESET_PAYMENTS_STATUSES:
            if(action.payment == 'all'){

                return initialState
            }
            return {
                ...state,
                [action.payment]: initialState[action.payment]
            }
        default:
            return state
    }
};

export default paymentReducer
