import {toast} from "react-toastify";

export const reset = (state = {processing: false}, action) => {
  switch (action.type) {
    case "RESTORE_PASSWORD": {
        toast.success('Password reset email was sent')
        return { ...state}
    }
    case "RESET_PASSWORD": {
        toast.success('Your password has been reset!')
        return { ...state, reset: action.reset }
    }
    case "RESET_PASSWORD_ERROR": {
        toast.error(action.message)
        return state
    }
    case "RESET_PASSWORD_PROCESSING": {
        return { ...state, processing: action.payload }
    }
    default: {
      return state
    }
  }
}
 