import { combineReducers } from "redux"
import { reset } from "./ressetPasswordReducers"
import { register } from "./register"

const authReducers = combineReducers({
  reset,
  register
})

export default authReducers
