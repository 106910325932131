const initialState = {
    users_list:[],
    user: {
        id: 0,
        email: "",
        fname: "",
        lname: "",
        reviews: 0,
        company: {
            name: '',
            address: '',
            zip: '',
            city: '',
            country: ''
        },
        lang: null
    },
}

const authorizedUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_USERS":
            return {...state, users_list: action.users};
        case "FETCH_AUTHORIZED_USER_INFO":
            return {...state, user: action.user};
        case "CHANGE_USER_LANGUAGE":
            return {...state, user: {...state.user, lang: action.payload}};
        case "RESET_USER_OBJECT":
            return {...state, user: initialState.user};
        case "USER_UNSUBSCRIBE":
            return state;
        default:
            return state
    }
};

export default authorizedUserReducer
