import React from "react"

const FullPageLayout = ({children, ...rest}) => {
    return (
        <div className="full-layout wrapper">

            <main className="main d-flex align-items-center justify-content-center w-100 min-vh-100 pb-3">{children}</main>

            <div id="modal-root"></div>

        </div>
    )
}

export default FullPageLayout
