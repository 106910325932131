import axios from "axios"

export const ACTIONS = {
    GET_ALL_JOBS: "GET_ALL_JOBS",
    SET_IS_FETCHING: "SET_IS_JOBS_FETCHING",
}

export const getAllJobs = (product_id, type, isReviews) => {
    let url = `/api/product/${product_id}/jobs/`;
    if(type === 'finished'){
        url+='completed'
    }else{
        url+='active'
    }
    return dispatch => {
        dispatch(setIsFetching(product_id))
        axios
            .get(url, {params: {
                    type: isReviews ? 'review-campaign' : 'sales-campaign'
                }})
            .then(response => {
                let payback = [];

                if(isReviews){
                    payback = Object.keys(response.data.reviewjobs).map((key) => response.data.reviewjobs[key])
                }else{
                    payback = Object.keys(response.data.sales_orders).map((key) => response.data.sales_orders[key])
                }

                dispatch({
                    type: ACTIONS.GET_ALL_JOBS,
                    payload: payback,
                    product_id: product_id,
                });
            })
            .catch(error => {
                dispatch({
                    type: ACTIONS.GET_ALL_JOBS,
                    payload: [],
                    product_id: product_id
                });
            })
        // const resp = await axios.get()
        
    }
};

export const setIsFetching = (bool) => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_IS_FETCHING,
            payload: bool,
        });
    }
};


