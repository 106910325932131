import {RESET_FETCH_MESSAGE, SET_FETCH_MESSAGE} from "../../actions/notification";


const initialState = {
    authError: '',
    fetchMessage: null
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case "RESET_LOGIN":
            return {...state, authError: ''};
        case "NOTIFY_VALIDATION_AUTH_ERROR":
            return {...state, authError: action.payload.authError};
        case SET_FETCH_MESSAGE:
            return { ...state, fetchMessage: action.message };
        case RESET_FETCH_MESSAGE:
            return { ...state, fetchMessage: null };
        default:
            return state
    }
};

export default notificationReducer
