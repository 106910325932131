const notices = {
  orderBought: ' Order Bought!',
  actionAfterPurchasedOrder: 'After bought it is impossible to change the order',
  orderFinish: 'The order has been completed. Submitted for writing reviews!',
  warningTypeProduct: 'Warning! This type of product is not intended for this workflow!',
  copyInBuffer: 'Data Copied!',
  profileNotFound: 'Profile not found',
  emptyFields: 'The fields cannot be empty. Please fill in the fields correctly',
  streetFieldError: "The 'street' field must not be empty",
  streetFieldUpdate: "The 'street' field has been successfully updated.",
  createdItem: (name) => `${name} was created successfully!`,
  addedItem: (name) => `${name} added successfully!`,
  updatedItem: (name) => `${name} updated successfully!`,
  deleteItem: (name) => `${name} removed successfully!`,
  savedItem: (name) => `${name} saved successfully!`,
  publishedItem: (name) => `${name} published successfully!`,
  emptyUrl: "Please enter url",
  emptyWPID: "The field cannot be empty.Please enter WPID",
  emptyDomain: "Please enter domain!",
  emptySelect: (field) => `The field ${field} cannot be empty.Please select ${field}`,
  errorAMZLink: 'Please insert a valid URL',
  invalidEmail: 'Please insert a valid Email',
  invalidPassword: 'Password must be at least 6 characters long',
  invalidIp: "You entered an invalid IP address",
  invalidDate: "You entered the wrong date format",
  invalidFormatFile: (format) => `File not in ${format} format`,
  invalidSizeFile: (size) => `File should not be more than ${size}`,
  invalidFileData: "Incorrect file data format",
  productExists: "This product has already been added to the list.",
  sendToFreezer: "Account was successfully created and sent to freezer for 24 hours",
  profileGetPublicIp: "Before starting work with profiles, please change the Public IP",
  noSelect: (name) => `No ${name} selected. Please select ${name}`,
  noAddedProducts: "No products added. Please add products",
  noLockedProducts: "Products are not locked. Please lock products",
  errorOrderTypesProducts: "This order must contain two types of products",
  isLockModem: "Modem is locked.Obtaining a public IP is already underway.Wait for completion and try again",
  notSelectedModemUser: "No modem selected! Please ask your administrator to assign you a modem.",
  existsProfileButNotAvailable: "Profile exists but is not available at this stage",
  beforeProceedOrder: (id)=>`Account #${id} has been successfully created and is ready for further use. To proceed to order, first select a workflow type.`,
  error: "Sorry, something went wrong",
  success: "Success!",
  info: "Something happened",
  warning: "Something could have gone wrong",
};

export default notices;
