import {toast} from "react-toastify";

const initialState = {
    send_code: false,
    send_timeout: 0,
    send_code_error: null
}

export const register = (state = initialState, action) => {
    switch (action.type) {
        case "AUTHORIZED_CODE_SEND": {
            return { ...state, send_code: action.payload.status, send_timeout: action?.payload?.timeout || initialState.send_timeout }
        }
        case "RESET_AUTHORIZED_CODE_DATA": {
            return { ...initialState }
        }
        case "AUTHORIZED_CODE_SEND_ERROR": {
            return { ...state, send_code_error: action.payload }
        }
        case "RESET_AUTHORIZED_CODE_SEND_ERROR": {
            return { ...state, send_code_error: null }
        }
        default: {
            return state
        }
    }
}
