import axios from "axios"
import {setFetchMessage} from "../notification";
import notices from "../../../utils/notices";
import {getLoggedUserInfo} from "../user";

export const ACTIONS = {
    GET_ALL_PRODUCTS: "GET_ALL_PRODUCTS",
    SET_IS_FETCHING: "SET_IS_PRODUCTS_FETCHING",
    SET_CURRENT_PROD_PAGE: "SET_CURRENT_PROD_PAGE",
    CREATE_PRODUCT: "CREATE_PRODUCT",
    SUCCESS_CREATE_PRODUCT: "SUCCESS_CREATE_PRODUCT",
    ERROR_CREATE_PRODUCT: "ERROR_CREATE_PRODUCT",
    RESET_ERROR_PRODUCT: "RESET_ERROR_PRODUCT",
    GET_AMAZON_PRODUCT_SELLERS: "GET_AMAZON_PRODUCT_SELLERS",
    CLEAR_SELLER: "CLEAR_SELLER",
    SUCCESS_CREATE: "SUCCESS_CREATE_PRODUCT",
    RESET_SUCCESS_CREATE: "RESET_SUCCESS_CREATE_PRODUCT",
    RESET_SELLERS_DATA: "RESET_SELLERS_DATA_PRODUCT",
    DELETE_PRODUCT: "DELETE_PRODUCT",
    ERROR_DELETE_PRODUCT: "ERROR_DELETE_PRODUCT",
    GET_PRODUCT_NOT_TAKEN_JOBS: "GET_PRODUCT_NOT_TAKEN_JOBS",
    CAN_CREATE_MANUAL_PRODUCT: "CAN_CREATE_MANUAL_PRODUCT",
    GET_PACKCAGE_DETAILS: "GET_PACKCAGE_DETAILS",
    RESET_PACKCAGE_DETAILS: "RESET_PACKCAGE_DETAILS",
    CREATING_PACKCAGE_ORDER: "CREATING_PACKCAGE_ORDER",
    SET_PACKCAGE_FETCH: "SET_PACKCAGE_FETCH",
    RESET_PACKCAGE_ORDER: "RESET_PACKCAGE_ORDER",
}


export const getAllProducts = (currentPage = 1, perPage = 5, asin = "", market = "de") => {

    return dispatch => {
        dispatch(setIsFetching(true))
        axios
            .get(
                `/api/product/list`,
                {params: {per_page: perPage, page: currentPage, asin: asin, market: market}}
            )
            .then(response => {
                dispatch(setCurrentPage(currentPage))
                dispatch({
                    type: ACTIONS.GET_ALL_PRODUCTS,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: ACTIONS.GET_ALL_PRODUCTS,
                    payload: {data: []}
                });
                console.log(error)
                if (error && error?.response?.data?.message !== 'No one product created yet')
                    dispatch(setFetchMessage(error?.response?.data?.message, 'error'));
            })


    }
};

export const setIsFetching = (bool) => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_IS_FETCHING,
            payload: bool,
        });
    }
};

export const setCurrentPage = (page) => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_CURRENT_PROD_PAGE,
            payload: page,
        });
    }
};

export const getAmazonProductSellers = (link) => {
    return dispatch => {
        dispatch(setIsFetching(true))
        dispatch(resetError(false))

        axios
            .post(
                `/api/product/getSellers`,
                {
                    link
                })
            .then(response => {
                dispatch({
                    type: ACTIONS.GET_AMAZON_PRODUCT_SELLERS,
                    payload: response.data,
                });
                if(!response.data?.data.length){
                    dispatch(setFetchMessage('Sorry, this item has no seller.', 'warning'));
                }
            })
            .catch(error => {
                dispatch({
                    type: ACTIONS.ERROR_CREATE_PRODUCT,
                });
                if (error) {
                    if(error.response.data.message == "No one product seller found in Amazon"){
                        dispatch({
                            type: ACTIONS.CAN_CREATE_MANUAL_PRODUCT,
                        });
                    }
                    dispatch(setFetchMessage(error.response.data.message, 'error'));
                }
            })

    }
};

export const clearSellers = () => {
    return dispatch => {
        dispatch({
            type: ACTIONS.CLEAR_SELLER,
            payload: [],
        });
    }
};


export const resetError = (data) => {
    return dispatch => dispatch({
        type: ACTIONS.RESET_ERROR_PRODUCT
    })
}
export const resetSellersData = () => {
    return dispatch => dispatch({
        type: ACTIONS.RESET_SELLERS_DATA
    })
}

export const resetSuccessCreate = () => {
    return dispatch => dispatch({
        type: ACTIONS.RESET_SUCCESS_CREATE
    })
}

export const deleteProduct = (product_id, callback = ()=>{}) => {
    return dispatch => {
        axios
            .delete(`/api/product/${product_id}`)
            .then(response => {
                dispatch({
                    type: ACTIONS.DELETE_PRODUCT,
                    payload: response.data,
                });
                callback();
                dispatch(setFetchMessage(notices.success, 'success'));
                
            })
            .catch(error => {
                dispatch({
                    type: ACTIONS.ERROR_DELETE_PRODUCT,
                });
                dispatch(setFetchMessage(error.response.data.message, 'error'));
            })

    }
};
export const createProduct = (data) => {
    return dispatch => {
        dispatch(setIsFetching(true))
        dispatch(resetError(false))
        dispatch(resetSuccessCreate())
        axios
            .post(
                `/api/product`,
                data)
            .then(response => {
                if (response.status === 'error') {
                    dispatch({
                        type: ACTIONS.ERROR_CREATE_PRODUCT,
                        payload: response.data,
                    });
                    dispatch(setFetchMessage(response.message, 'error'));
                } else {
                    dispatch({
                        type: ACTIONS.SUCCESS_CREATE_PRODUCT,
                        payload: response.data,
                    });
                    dispatch(setFetchMessage(notices.success, 'success'));
                }
                    dispatch(getLoggedUserInfo())
                    dispatch(getAllProducts())
            })
            .catch(error => {
                console.log(error.response)
                dispatch({
                    type: ACTIONS.ERROR_CREATE_PRODUCT,
                });
                dispatch(setFetchMessage(error.response.data.message, 'error'));
            })

    }
};

export const updateProductManually = (product_id, data) => {
    return dispatch => {
        dispatch(setIsFetching(true))
        dispatch(resetError(false))
        dispatch(resetSuccessCreate())
        axios
            .put(
                `/api/product-manually/${product_id}`,
                data)
            .then(response => {
                if (response.status === 'error') {
                    dispatch({
                        type: ACTIONS.ERROR_CREATE_PRODUCT,
                        payload: response.data,
                    });
                    dispatch(setFetchMessage(response.message, 'error'));
                } else {
                    dispatch({
                        type: ACTIONS.SUCCESS_CREATE_PRODUCT,
                        payload: response.data,
                    });
                    dispatch(setFetchMessage('Thank you! We will approve your product within 24 hours.', 'success'));
                }
                dispatch(getLoggedUserInfo())
                dispatch(getAllProducts())
            })
            .catch(error => {
                console.log(error.response)
                dispatch({
                    type: ACTIONS.ERROR_CREATE_PRODUCT,
                });
                dispatch(setFetchMessage(error.response.data.message, 'error'));
            })
    }
};

export const createProductManually = (data) => {
    return dispatch => {
        dispatch(setIsFetching(true))
        dispatch(resetError(false))
        dispatch(resetSuccessCreate())
        axios
            .post(
                `/api/product-manually`,
                data)
            .then(response => {
                if (response.status === 'error') {
                    dispatch({
                        type: ACTIONS.ERROR_CREATE_PRODUCT,
                        payload: response.data,
                    });
                    dispatch(setFetchMessage(response.message, 'error'));
                } else {
                    dispatch({
                        type: ACTIONS.SUCCESS_CREATE_PRODUCT,
                        payload: response.data,
                    });
                    dispatch(setFetchMessage('Thank you! We will approve your product within 24 hours.', 'success'));
                }
                dispatch(getLoggedUserInfo())
                dispatch(getAllProducts())
            })
            .catch(error => {
                console.log(error.response)
                dispatch({
                    type: ACTIONS.ERROR_CREATE_PRODUCT,
                });
                dispatch(setFetchMessage(error.response.data.message, 'error'));
            })
    }
};

export const getProductNotTakenJobs = (product_id, callback = ()=>{}) => {
    return dispatch => {
        axios
            .get(`/api/product/${product_id}/not-taken-jobs`)
            .then(response => {
                dispatch({
                    type: ACTIONS.GET_PRODUCT_NOT_TAKEN_JOBS,
                    payload: response.data.result,
                });
                callback();
            })
            .catch(error => {
                console.log(error)
                dispatch({
                    type: ACTIONS.GET_PRODUCT_NOT_TAKEN_JOBS,
                    payload: 0,
                });
                callback();
                dispatch(setFetchMessage(error.response.data.message, 'error'));
            })

    }
};

export const editProductPrice = (product_id, price, callback = ()=>{}) => {
    return dispatch => {
        axios
            .put(`/api/product/${product_id}/edit-price`, {price:price})
            .then(response => {
                dispatch(setFetchMessage(notices.success, 'success'));
                dispatch(getLoggedUserInfo())
                callback(response.data);
            })
            .catch(error => {
                dispatch(setFetchMessage(error.response.data.message, 'error'));
            })

    }
};

export const getPackageDetails = (pack) => {
    return dispatch => {
        axios
            .get(`/api/review-package/details?reviews=${pack}`)
            .then(response => {
                dispatch({
                    type: ACTIONS.GET_PACKCAGE_DETAILS,
                    payload: response.data
                })

            })
            .catch(error => {
                dispatch(setFetchMessage(error.response.data.message, 'error'));
            })

    }
}

export const setPackageOrderFetching = (fetch = true) => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_PACKCAGE_FETCH,
            payload: fetch
        })
    }
}

export const createPackageOrder = (reviews) => {
    // POST, {{url}}/api/review-package     {reviews - required|in:50,100,200}
    return dispatch => {
        dispatch(setPackageOrderFetching())
        axios
            .post(`/api/review-package`, {reviews: reviews})
            .then(response => {
                dispatch(setFetchMessage(notices.success, 'success'));
                dispatch(setPackageOrderFetching(false))
                dispatch({
                    type: ACTIONS.CREATING_PACKCAGE_ORDER,
                    payload: response.data.data
                })
            })
            .catch(error => {
                if(error?.response?.data){
                    dispatch(setFetchMessage(error.response.data.message, 'error'));
                }else{
                    dispatch(setFetchMessage('createPackageOrder error', 'error'));
                }
                dispatch(setPackageOrderFetching(false))

            })

    }
}

export const resetPackageOrder = () => {
    return dispatch => {
        dispatch({
            type: ACTIONS.RESET_PACKCAGE_ORDER,
        })
    }
}

export const resetPackDetails = () => {
    return dispatch => {
        dispatch({
            type: ACTIONS.RESET_PACKCAGE_DETAILS,
        })
    }
}