import axios from "axios"
import {setFetchMessage} from "../notification";
import notices from "../../../utils/notices";
export const ACTIONS = {
    GET_ORDERS_LIST: "GET_ORDERS_LIST",
    DOWNLOAD_ORDER_INVOICE: "DOWNLOAD_ORDER_INVOICE",
}


export const getOrdersPaginate = (params) => {

    return  dispatch => {
        axios
            .get(`/api/order/list`,{ params: params })
            .then(response => {
                console.log(response)
                dispatch({
                    type: ACTIONS.GET_ORDERS_LIST,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: ACTIONS.GET_ORDERS_LIST,
                    payload: {
                        data: [],
                        meta:{total:0}
                    }
                });
                if(error.response.status != 400)
                    dispatch(setFetchMessage(error.response.data.message, 'error'));
            })



    }
};

export const downloadInvoice = (order_id, type='campaign') => {
    let url = `/api/order/${order_id}/invoice`;
    if(type === "Custom invoice"){
        url = `/api/order/${order_id}/custom-invoice`;
    }
  return dispatch => {
    axios.get(url, {
        responseType: 'blob'
    }).then((response) => {
        console.log(response)
        dispatch({
            type: "DOWNLOAD_PAYMENT_INVOICE",
            data: response.data
        })
    }).catch(err => console.log(err))
  }
};