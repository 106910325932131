import { combineReducers } from "redux"
import auth from "./auth/"
import authorizedUserReducer from "./user";
import notificationReducer from "./notification/"
import productsReducer from "./products";
import ordersReducer from "./orders";
import refundsReducer from "./refunds";
import paymentReducer from "./payment";
import jobsReducer from "./jobs";
import campaignsReducer from "./campaigns/index";

const rootReducer = combineReducers({
    auth: auth,
    authorizedUser: authorizedUserReducer,
    notification: notificationReducer,
    products: productsReducer,
    campaigns: campaignsReducer,
    orders: ordersReducer,
    refunds: refundsReducer,
    payment: paymentReducer,
    jobs: jobsReducer,
});

export default rootReducer
